var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pt-4 pa-4", attrs: { flat: "", elevation: 0 } },
            [
              _c(
                "v-layout",
                { staticClass: "mr-3 mb-5 flex justify-end flex-column" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "secondary-text text-lg-left form-title"
                        },
                        [_vm._v("Intredes")]
                      ),
                      _c("div", { staticClass: "flex pr-0" }, [
                        _c(
                          "div",
                          { staticClass: "mt-3 d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  "x-large": "",
                                  color: "blue-grey",
                                  loading: _vm.dlEntries,
                                  disabled: _vm.loading
                                },
                                on: { click: _vm.getEntriesList }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "", dark: "" } },
                                  [_vm._v("mdi-file-excel")]
                                ),
                                _vm._v(" Download Intredes ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$entriesList",
                              showFilters: true,
                              showSearchBar: true
                            },
                            on: { filterData: _vm.setFilterOnList }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.canEdit
                    ? _c(
                        "div",
                        { staticClass: "flex-row d-inline-flex align-center" },
                        [
                          _c("v-autocomplete", {
                            staticClass: "template-select mr-5",
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              small: "",
                              label: "Mail template kiezen",
                              clearable: "",
                              items: _vm.mailTemplates,
                              "item-text": "name",
                              "item-value": "_id"
                            },
                            on: { change: _vm.toggleDisableButton },
                            model: {
                              value: _vm.selectedMailTemplate,
                              callback: function($$v) {
                                _vm.selectedMailTemplate = $$v
                              },
                              expression: "selectedMailTemplate"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                disabled: _vm.disableMail
                              },
                              on: {
                                click: function($event) {
                                  return _vm.mailSelected()
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "primary" }
                                },
                                [_vm._v("mdi-email-outline")]
                              ),
                              _vm._v(" Onderstaande selectie mailen ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "v-data-table",
                {
                  attrs: {
                    headers: _vm.getHeaders,
                    items: _vm.tenantsV2,
                    search: _vm.search,
                    options: _vm.options,
                    "server-items-length": _vm.totalTenants,
                    loading: _vm.loading,
                    "item-key": "email",
                    "footer-props": { "items-per-page-options": [5, 10, 15] }
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function(ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function(item) {
                              return _c(
                                "tr",
                                [
                                  _vm.extended
                                    ? _c(
                                        "td",
                                        {
                                          class:
                                            "status-border-" +
                                            item.paymentDue.statusCode
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-dots-vertical"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-list",
                                                    { attrs: { dense: "" } },
                                                    _vm._l(
                                                      _vm.menuItems,
                                                      function(menuItem, i) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: i,
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.menuItemClicked(
                                                                  menuItem.id,
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c("v-icon", {
                                                                  domProps: {
                                                                    textContent: _vm._s(
                                                                      menuItem.icon
                                                                    )
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      textContent: _vm._s(
                                                                        menuItem.label
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-list",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.menuItemClicked(
                                                                "delete",
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-icon",
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-outline"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "Verwijderen"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.extended
                                    ? _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(item.tenant.tenantId))]
                                      )
                                    : _vm._e(),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(item.startDate)
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("formatDate")(item.endDate))
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(_vm._s(item.project.name) + " ")
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "unitDetail",
                                              params: { id: item.unit._id }
                                            },
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.unit.name) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(_vm._s(item.tenant.fullName))
                                  ]),
                                  _vm._l(_vm.payments, function(payment) {
                                    return _vm.extended
                                      ? _c(
                                          "td",
                                          { staticClass: "justify-center" },
                                          [
                                            item.prices &&
                                            item.prices[payment.id]
                                              ? _c(
                                                  "span",
                                                  [
                                                    item.fees[payment.id] ===
                                                    "PAID"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              color: "green"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.paymentDialogItem = {
                                                                  _id:
                                                                    item.fees[
                                                                      "_id"
                                                                    ],
                                                                  payment: payment,
                                                                  documents:
                                                                    item.documents,
                                                                  tenantId:
                                                                    item.tenant
                                                                      ._id,
                                                                  brandName:
                                                                    item.brand
                                                                      .name
                                                                }
                                                                _vm.paymentDialog = true
                                                                _vm.checkToUpdate =
                                                                  item.fees
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-checkbox-marked-circle-outline "
                                                            )
                                                          ]
                                                        )
                                                      : item.fees[
                                                          payment.id
                                                        ] === "TO_CHECK"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              color: "orange"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.paymentDialogItem = {
                                                                  _id:
                                                                    item.fees[
                                                                      "_id"
                                                                    ],
                                                                  payment: payment,
                                                                  documents:
                                                                    item.documents,
                                                                  tenantId:
                                                                    item.tenant
                                                                      ._id,
                                                                  brandName:
                                                                    item.brand
                                                                      .name
                                                                }
                                                                _vm.paymentDialog = true
                                                                _vm.checkToUpdate =
                                                                  item.fees
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-alert-circle-outline "
                                                            )
                                                          ]
                                                        )
                                                      : item.fees[
                                                          payment.id
                                                        ] === "UNPAID"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              color: "red"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.paymentDialogItem = {
                                                                  _id:
                                                                    item.fees[
                                                                      "_id"
                                                                    ],
                                                                  payment: payment,
                                                                  documents:
                                                                    item.documents,
                                                                  tenantId:
                                                                    item.tenant
                                                                      ._id,
                                                                  brandName:
                                                                    item.brand
                                                                      .name
                                                                }
                                                                _vm.paymentDialog = true
                                                                _vm.checkToUpdate =
                                                                  item.fees
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-close-circle-outline "
                                                            )
                                                          ]
                                                        )
                                                      : _c("v-icon")
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  _c("td", [
                                    item.fees.extras
                                      ? _c(
                                          "span",
                                          [
                                            item.fees.extras === "PAID"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "green" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.fees.extras === "UNPAID"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "red" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-close-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.fees.extras === "TO_CHECK"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "orange" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-alert-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _c("v-icon")
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c("td", [
                                    item.registrationDocument
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.documentDialogItem = {
                                                  item: item,
                                                  type: "reg_document"
                                                }
                                                _vm.documentDialog = true
                                                _vm.checkToUpdate = item
                                              }
                                            }
                                          },
                                          [
                                            item.registrationDocument ===
                                            "APPROVED"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "green" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.registrationDocument ===
                                            "NOT_PRESENT"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "red" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-close-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.registrationDocument ===
                                            "TO_JUDGE"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "orange" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-alert-circle-outline"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm.extended
                                    ? _c(
                                        "td",
                                        { staticClass: "justify-center" },
                                        [
                                          item.tenant["passportStatus"] ===
                                          "NOT_PRESENT"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "red" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialogItem = item
                                                      ;(_vm.dialog = true),
                                                        (_vm.checkToUpdate =
                                                          item.tenant)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-close-circle-outline "
                                                  )
                                                ]
                                              )
                                            : item.tenant["passportStatus"] ===
                                              "TO_JUDGE"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "orange" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialogItem = item
                                                      ;(_vm.dialog = true),
                                                        (_vm.checkToUpdate =
                                                          item.tenant)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-alert-circle-outline "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "green" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialogItem = item
                                                      ;(_vm.dialog = true),
                                                        (_vm.checkToUpdate =
                                                          item.tenant)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-checkbox-marked-circle-outline "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.extended
                                    ? _c(
                                        "td",
                                        { staticClass: "justify-center" },
                                        [
                                          item.signed === true
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "green" }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-checkbox-marked-circle-outline "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "red" }
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-close-circle-outline "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "justify-center text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              item.signedAtDate
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.type) + " ")
                                  ])
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "no-data" },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            value: true,
                            color: "grey",
                            icon: "mdi-warn"
                          }
                        },
                        [_vm._v(" Sorry, geen gebruikers gevonden ")]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between mb-6" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        loading: _vm.dlRentalDiaryLoading,
                        disabled: _vm.loading || !_vm.canDownload,
                        outlined: ""
                      },
                      on: { click: _vm.getRentalDiaryData }
                    },
                    [_vm._v("Download huurdagboek data")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm.dialog
                ? _c("passport-dialog", {
                    attrs: { item: _vm.dialogItem },
                    on: { save: _vm.updateTenant }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "300px" },
              model: {
                value: _vm.paymentDialog,
                callback: function($$v) {
                  _vm.paymentDialog = $$v
                },
                expression: "paymentDialog"
              }
            },
            [
              _vm.paymentDialog
                ? _c("payment-dialog", {
                    attrs: { item: _vm.paymentDialogItem },
                    on: { save: _vm.updateTenantPayment }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "300px" },
              model: {
                value: _vm.documentDialog,
                callback: function($$v) {
                  _vm.documentDialog = $$v
                },
                expression: "documentDialog"
              }
            },
            [
              _vm.documentDialog
                ? _c("document-dialog", {
                    attrs: { item: _vm.documentDialogItem },
                    on: { save: _vm.updateContractDocument }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog"
              }
            },
            [
              _vm.confirmDeleteDialog
                ? _c("confirm-delete", {
                    attrs: { item: _vm.confirmDeleteDialogItem },
                    on: {
                      cancel: function($event) {
                        _vm.confirmDeleteDialogItem = null
                        _vm.confirmDeleteDialog = false
                      },
                      delete: _vm.deleteContract
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px" },
              model: {
                value: _vm.MaillogDialog,
                callback: function($$v) {
                  _vm.MaillogDialog = $$v
                },
                expression: "MaillogDialog"
              }
            },
            [
              _vm.MaillogDialog
                ? _c("maillog-dialog", {
                    attrs: { item: _vm.MaillogDialogItem }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.createContractDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "880px", scrollable: "", persistent: "" },
              model: {
                value: _vm.createContractDialog,
                callback: function($$v) {
                  _vm.createContractDialog = $$v
                },
                expression: "createContractDialog"
              }
            },
            [
              _c("create-contract-dialog", {
                attrs: {
                  isLoading: _vm.isSavingContract,
                  extension: _vm.extension,
                  item: _vm.createContractDialogItem,
                  editItem: _vm.editContract,
                  canEdit: _vm.getRights.FOLLOW_UP_ENTIES
                },
                on: { save: _vm.createContract, close: _vm.closeContractDetail }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px" },
              model: {
                value: _vm.commentDialog,
                callback: function($$v) {
                  _vm.commentDialog = $$v
                },
                expression: "commentDialog"
              }
            },
            [
              _vm.commentDialog
                ? _c("comments-dialog", {
                    attrs: { item: _vm.commentDialogItem },
                    on: { save: _vm.updateRemarks, delete: _vm.deleteRemark }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }