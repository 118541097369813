var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "ma-5", attrs: { md12: "", xs12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "card pa-4" },
            [
              _c("div", { staticClass: "header" }, [
                _c(
                  "h1",
                  { staticClass: "secondary-text text-lg-left form-title" },
                  [_vm._v(" Huurders "), _c("v-spacer")],
                  1
                ),
                _c("h4", { staticClass: "text-sm-left grey--text" }, [
                  _vm._v("Overzicht huurders ")
                ])
              ]),
              _c("entry-list", { attrs: { extended: false } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }